var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-bg" },
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "review-body" },
        [
          _c("TopBar"),
          _c(
            "div",
            { staticClass: "filter-bar boxarea" },
            [_c("SelectLayer")],
            1
          ),
          _c("section", { staticClass: "review-management" }, [
            _c("div", { staticClass: "boxarea" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$t("negative_management")))]),
              _vm.currentLang == "ko"
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.term == ""
                            ? "현재까지"
                            : "지난" + _vm.term + "개월동안"
                        ) +
                        " 받은 리뷰 " +
                        _vm._s(_vm.comma(_vm.chartTotalCount)) +
                        "개 중, 별점 3점 이하를 받은 리뷰가 " +
                        _vm._s(_vm.underThree) +
                        "개 입니다. "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.term == ""
                            ? "현재까지"
                            : "지난" + _vm.term + "개월동안"
                        ) +
                        " 감성 점수 Negative를 받은 리뷰가 "
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.sentiment_2))]),
                    _vm._v("개 입니다. ")
                  ])
                : _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.comma(_vm.underThree)) +
                        " of the reviews " +
                        _vm._s(
                          _vm.term == ""
                            ? "until now"
                            : "in the last " + _vm.term + " months"
                        ) +
                        " rated 3 stars or less. "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.comma(_vm.sentiment_2)) +
                        " of the reviews " +
                        _vm._s(
                          _vm.term == ""
                            ? "until now"
                            : "in the last " + _vm.term + " months"
                        ) +
                        " rated negative sentiment score. "
                    )
                  ]),
              _c(
                "div",
                { staticClass: "chartBox" },
                [
                  _c("VueApexCharts", {
                    ref: "chart",
                    class: { fullSize: _vm.index == 1 },
                    attrs: {
                      type: "line",
                      height: "500",
                      width: _vm.chartWidth,
                      options: _vm.chartOptions,
                      series: _vm.series
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "boxarea-table" }, [
              _c(
                "table",
                {
                  directives: [
                    {
                      name: "infinite-scroll",
                      rawName: "v-infinite-scroll",
                      value: _vm.load,
                      expression: "load"
                    }
                  ],
                  staticClass: "infinite-list-wrapper"
                },
                [
                  _c("tr", [
                    _c("td", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("dissatisfied_reviews_comment")))
                      ])
                    ]),
                    _c("td", [_vm._v("Date")]),
                    _c("td", [_vm._v("Rating")]),
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: "" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleSort()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("response_requirment_rate")) +
                                " "
                            ),
                            _vm.sorting == 1
                              ? _c("span", [_vm._v("▼")])
                              : _c("span", [_vm._v("▲")])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._l(_vm.reviewList, function(item, i) {
                    return _c("tr", { key: i }, [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(item.review_source) +
                            " " +
                            _vm._s(item.reviewer_name) +
                            " "
                        ),
                        _c("p", [_vm._v(_vm._s(item.review))])
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.moment(item.review_date).format("YYYY.MM.DD")
                          )
                        )
                      ]),
                      _c("td", [_vm._v(_vm._s(item.rating))]),
                      _c(
                        "td",
                        [
                          _c("Small-Donut", {
                            attrs: { dataA: [item.NPS_Score] }
                          })
                        ],
                        1
                      )
                    ])
                  })
                ],
                2
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "loading"
                },
                [_vm._v("Loading...")]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }